import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SignupIdentityForm from "../components/formIdentitySignup"
import {
    Section,
    SectionHeading,
    SectionHeaderLedeDiv,
    SectionBlock,
    SectionBlockEmbedCalendar,
} from "../styledComponents/section"

const Heading = ({ level = 1, location, children, align }) => (
    <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
        {children}
    </SectionHeading>
)

const GoogleScheduleAppointments = ({ data, location }) => {

    return (
        <>
            <Helmet>
                <meta name={`robots`} content={`noindex, nofollow`} />
            </Helmet>
            <Layout location={location} hideNewsletterSignUp>
                <Section>
                    <Heading level={1} align="center">
                        Schedule an appointment
                    </Heading>
                    <SectionBlockEmbedCalendar>
                    <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ27vvu5kYfk-pUmKDWY3i47pCANNo38cZ_3ZN_5divs-GbEl5TPEJ_G7yQdhbnQLIQ0YeepcF-O?gv=true" width="100%" style={{'width':'100%!important'}} height="600" frameborder="0"></iframe>
                    </SectionBlockEmbedCalendar>
                </Section>
            </Layout>
        </>
    )
}

export default GoogleScheduleAppointments